import {
  CheckoutDeliveryOption,
  CheckoutErrorFragment,
  CheckoutFragment,
  CheckoutUnprocessableErrorsFragment,
  DeliveryOption,
} from '@/gql';
import { isCheckoutErrorFragment, isCheckoutUnprocessableErrorsFragment } from '@/lib/gql';
import logger from '../logger';

// Shipping enums
export const DELIVERY_OPTION_TO_CHECKOUT_DELIVERY_OPTION: Record<DeliveryOption, CheckoutDeliveryOption> = {
  standard: 'fullStandardCost',
  rush: 'fullRushCost',
  super_rush: 'fullSuperRushCost',
};
export const CHECKOUT_DELIVERY_OPTION_TO_DELIVERY_OPTION: Record<CheckoutDeliveryOption, DeliveryOption> = {
  fullStandardCost: 'standard',
  fullRushCost: 'rush',
  fullSuperRushCost: 'super_rush',
};

/**
 * Convert a DeliveryOption to a CheckoutDeliveryOption
 *
 * @param deliveryOption
 * @returns
 */
export function deliveryOptionToCheckoutDeliveryOption(
  deliveryOption: DeliveryOption | undefined
): CheckoutDeliveryOption | undefined {
  return (deliveryOption && DELIVERY_OPTION_TO_CHECKOUT_DELIVERY_OPTION[deliveryOption]) || undefined;
}

/**
 * Convert a CheckoutDeliveryOption to a DeliveryOption
 *
 * @param checkoutDeliveryOption
 * @returns
 */
export function checkoutDeliveryOptionToDeliveryOption(
  checkoutDeliveryOption: CheckoutDeliveryOption | undefined
): DeliveryOption | undefined {
  return (checkoutDeliveryOption && CHECKOUT_DELIVERY_OPTION_TO_DELIVERY_OPTION[checkoutDeliveryOption]) || undefined;
}

/**
 * Convert expected checkout errors to displayable checkout/promo code errors
 *
 * @param error
 */
export function checkoutErrorToDisplay(
  error: CheckoutErrorFragment | CheckoutUnprocessableErrorsFragment,
  ctx?: { promoCode?: string }
): {
  checkoutError: string | undefined;
  promoCodeError: string | undefined;
} {
  let checkoutError: string | undefined;
  let promoCodeError: string | undefined;

  // UI can currenly only handle one error (plus a promo code error),
  // so just latch the first encountered of each flavor
  const latchCheckoutError = (value: string) => {
    if (!checkoutError && value) {
      checkoutError = value;
    }
  };
  const latchPromoError = (value: string) => {
    if (!promoCodeError && value) {
      promoCodeError = value;
    }
  };

  if (isCheckoutErrorFragment(error)) {
    if (error?.displayMessage) {
      checkoutError = error.displayMessage;
    }
  } else if (isCheckoutUnprocessableErrorsFragment(error)) {
    error.errors?.forEach((cursor) => {
      // currently emitted by:
      // rails-teespring/apps/commerce/app/controllers/commerce/api/v1/checkout/pricing_summary_controller.rb
      const ptr = cursor?.source?.pointer;
      switch (cursor?.code) {
        case 'value_not_supported':
          if (ptr === '/attributes/currency') {
            latchCheckoutError('Invalid currency option.');
          } else if (ptr === '/attributes/deliveryOption') {
            latchCheckoutError('Invalid delivery option.');
          }
          break;
        case 'value_is_required':
          if (ptr === '/attributes/deliveryOption') {
            latchCheckoutError('Delivery option is required.');
          }
          break;
        case 'invalid_value':
          if (ptr === '/attributes/promoCode') {
            if (ctx?.promoCode) {
              latchPromoError(`Promo code ('${ctx?.promoCode}') is invalid. Please try a different code.`);
            } else {
              latchPromoError('Promo code is invalid. Please try a different code.');
            }
          }
          break;
        case 'variants_must_belong_to_same_seller':
          if (ptr === '/attributes/lineItems') {
            latchCheckoutError('Items from multiple sellers.');
          }
          break;
        default:
          break;
      }
    });
  }

  if (!checkoutError && !promoCodeError) {
    logger.warn('Unexpected unprocessable checkout error:', JSON.stringify(error));
    checkoutError = 'Unexpected error, please try again.';
  }

  return { checkoutError, promoCodeError };
}

/**
 * Build a confirmation page relative url for a checkout
 *
 * @param checkout
 * @returns The root relative path to the confirmation page
 */
export function buildCheckoutConfirmationRoute(checkout: Pick<CheckoutFragment, 'orderLookupNumber'>): string {
  return `/confirmation?orderLookupNumber=${checkout.orderLookupNumber || ''}`;
}
