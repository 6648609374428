import React from 'react';
import styles from './index.module.css';

/**
 * `FormTextBox` - A flexible and stylized text input component for React forms.
 *
 * Features:
 * - Supports various input types (controlled by `type` prop).
 * - Optional inline or standard label.
 * - Displays attention messages for warnings or errors.
 * - Customizable with CSS classes and includes an optional inline button.
 *
 * Props include `label`, `inputClass`, `placeholder`, `inputContainerClass`, `name`,
 * `setValue`, `type`, `prevValue`, `disabled`, `isWarning`, `buttonLabel`, `attentionMessage`,
 * `disableInlineButton`, `isInlineLabel`, `onInlineButtonClick`.
 *
 * Usage: Ideal for forms needing styled input fields with enhanced features like inline labels or integrated buttons.
 */

interface FormTextBoxProps {
  label?: string;
  inputClass?: string;
  placeholder?: string;
  inputContainerClass?: string;
  name: string;
  setValue: (value: string) => void;
  type: string;
  prevValue?: string;
  disabled?: boolean;
  isWarning?: boolean;
  buttonLabel?: string;
  attentionMessage?: string;
  disableInlineButton?: boolean;
  isInlineLabel?: boolean;
  onInlineButtonClick?: () => void;
  inputRef?: React.RefObject<HTMLInputElement>;
}
const FormTextBox: React.FC<FormTextBoxProps> = ({
  label,
  name,
  setValue,
  type,
  prevValue = '',
  disabled = false,
  attentionMessage,
  buttonLabel,
  disableInlineButton = false,
  onInlineButtonClick,
  isInlineLabel = false,
  inputClass,
  inputContainerClass,
  placeholder,
  isWarning = false,
  inputRef,
}) => {
  const trimPrevValue = prevValue.trim();
  const hasPrevValue = trimPrevValue.length > 1 || Number(trimPrevValue) > 0;

  const inlineLabelClass = trimPrevValue.length > 0 ? '-translate-y-[1.15rem] scale-[0.8] text-primary bg-white' : '';

  return (
    <>
      <div className='relative w-full pb-2'>
        {!isInlineLabel && (
          <label htmlFor={name} className={styles.labelBase}>
            {label}
          </label>
        )}
        <div className={inputContainerClass}>
          <input
            id={name}
            type={type}
            name={name}
            placeholder={placeholder}
            value={prevValue}
            onChange={(e) => setValue(e.target.value)}
            disabled={disabled}
            className={inputClass || styles.inputBase}
            required
            ref={inputRef}
          />
          {isInlineLabel && (
            <label
              htmlFor={name}
              className={`${inlineLabelClass} peer-focus:-translate-y-[1.15rem] peer-focus:scale-[0.8] peer-focus:bg-white pointer-events-none absolute left-3 top-0 mb-0 origin-[0_0] pt-[0.37rem] leading-[2.15] text-neutral-500 transition-all duration-200 ease-out`}
            >
              {label}
            </label>
          )}
          {!!buttonLabel && hasPrevValue && !isWarning && (
            <button
              type='button'
              onClick={onInlineButtonClick}
              disabled={disableInlineButton}
              className={styles.inlineButton}
            >
              {buttonLabel}
            </button>
          )}
        </div>
      </div>
      {!!attentionMessage && hasPrevValue && (
        <p className={isWarning ? styles.warningText : styles.errorText}>{attentionMessage}</p>
      )}
    </>
  );
};

export default FormTextBox;
