import { useCallback, useState } from 'react';
import { createCheckout, updateCheckout, submitCheckout, getCheckout } from '@/api';
import {
  CheckoutDataInput,
  CheckoutErrorFragment,
  CheckoutFragment,
  CheckoutUnprocessableErrorsFragment,
  Currency,
  CustomerInput,
  PaymentPlatform,
  SubmitCheckoutInput,
  UpdateCheckoutInput,
} from '@/gql';
import { useStoreSlug } from '@/hooks/use-store-slug';
import { buildSku } from '@/lib/product';
import useCartState from '@/stores/cart-state';
import useCheckoutState from '@/stores/checkout-state';
import usePaymentMethodState from '@/stores/payment-method-state';
import { isCheckoutErrorFragment, isCheckoutFragment } from '@/lib/gql';
import logger from '@/lib/logger';

interface UpdateCheckoutOptions {
  checkoutId: string;
  storeSlug: string;
  customer?: CustomerInput;
  deliveryOption?: string;
  affiliate?: string;
  currency?: Currency;
  promoCode?: string;
  trafficSource?: string;
}

export const useCheckoutData = () => {
  const [checkoutError, setCheckoutError] = useState<String>('');
  const [checkoutLoading, setCheckoutLoading] = useState<Boolean>(false);

  const clearCheckout = useCallback(() => {
    const { setCart } = useCartState.getState();
    const { clearCheckout: checkoutStateClear } = useCheckoutState.getState();
    const paymentMethodState = usePaymentMethodState.getState();
    checkoutStateClear();
    setCart([]);
    paymentMethodState.reset();
  }, []);

  const createLocalCheckout = useCallback(async (storeSlug: string) => {
    setCheckoutLoading(true);
    // pull latest state data (to include changes made to
    // state after the callback was created)
    await useCartState.persist.rehydrate();

    const checkoutState = useCheckoutState.getState();

    const { cart } = useCartState.getState();
    const lineItems = cart.map((item) => ({
      sku: buildSku(item.itemGroupId, item.variationId, item.sizeId),
      quantity: item.quantity,
      fulfillmentDetails: {
        productionTechniques: [],
      },
    }));

    if (lineItems.length === 0) {
      checkoutState.clearCheckout();
      setCheckoutLoading(false);
      return '';
    } else {
      const checkoutVars: CheckoutDataInput = {
        affiliate: checkoutState.affiliate,
        lineItems,
        // promoCode: checkoutState.promoCode,
        storeSlug,
        trafficSource: checkoutState.trafficSource,
        currency: 'USD', // TODO: JYP ?
      };
      const result = await createCheckout(checkoutVars);
      checkoutState.handleCheckoutOpResult(result);
      if (isCheckoutFragment(result)) {
        setCheckoutLoading(false);
        return result.id;
      }
      if (isCheckoutErrorFragment(result)) setCheckoutError(result.displayMessage || '');
    }
    setCheckoutLoading(false);
    return '';
  }, []);

  const updateLocalCheckout = useCallback(async (updateCheckoutOptions: UpdateCheckoutOptions) => {
    setCheckoutLoading(true);
    const checkoutState = useCheckoutState.getState();
    const { customer, deliveryOption, affiliate, promoCode, storeSlug, trafficSource, currency, checkoutId } =
      updateCheckoutOptions;
    const checkoutVars: UpdateCheckoutInput = {
      data: {
        customer,
        deliveryOption,
        affiliate,
        promoCode,
        storeSlug,
        trafficSource,
        currency, // 'USD', // TODO: JYP ?
      },
      id: checkoutId,
    };

    // Attempt to update the checkout with the provided options.
    try {
      const result = await updateCheckout(checkoutVars);
      checkoutState.handleCheckoutOpResult(result);

      return result;
    } catch (error) {
      logger.error('Failed to update checkout:', error);
      return null;
    } finally {
      setCheckoutLoading(false);
    }
  }, []);

  const storeSlug = useStoreSlug();

  const getCheckoutData = useCallback(
    async (checkoutId: string) => {
      const checkoutState = useCheckoutState.getState();
      setCheckoutLoading(true);
      const result = await getCheckout(checkoutId, storeSlug);
      if (isCheckoutErrorFragment(result)) {
        setCheckoutError(result.displayMessage || '');
      }
      if (isCheckoutFragment(result)) {
        checkoutState.handleCheckoutOpResult(result);
      }
      setCheckoutLoading(false);
      return result;
    },
    [storeSlug]
  );

  const submitLocalCheckout = useCallback(
    async (
      // TODO: JYP - validate paymentMethodState?  or just let the checkout fail (but seems like a flow problem,
      // not something the user can recover from)
      paymentId?: string,
      paymentToken?: string,
      platform?: PaymentPlatform
    ): Promise<CheckoutFragment | CheckoutErrorFragment | CheckoutUnprocessableErrorsFragment | undefined> => {
      setCheckoutLoading(true);
      const checkoutState = useCheckoutState.getState();
      const paymentMethodState = usePaymentMethodState.getState();

      const submitVars: SubmitCheckoutInput = {
        storeSlug,
        platform: platform || 'stripe',
        paymentId: paymentId || paymentMethodState?.paymentId,
        paymentToken: paymentToken || paymentMethodState?.paymentIntentId,
        checkoutId: checkoutState.checkoutId,
        paymentMethodId: paymentMethodState?.paymentMethod?.id,
      };
      const result = await submitCheckout(submitVars);
      checkoutState.handleCheckoutOpResult(result);
      setCheckoutLoading(false);
      return result;
    },
    [setCheckoutLoading, storeSlug]
  );

  return {
    submitLocalCheckout,
    createLocalCheckout,
    updateLocalCheckout,
    getCheckoutData,
    checkoutError,
    checkoutLoading,
    clearCheckout,
  };
};
