import { getFulfillmentDetail } from '@/lib/preferences';
import { FulfillmentRegion } from '@/types/preferences';
import { useCallback, useMemo, useState } from 'react';
import { useRouter } from 'next/navigation';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getUserLocale } from 'get-user-locale';
import { DEFAULT_FULFILLMENT_REGION, MONOLITH_URL } from '@/constants';
import { useCheckoutData } from '@/hooks/use-checkout-data';
import { usePreviewStoreTheme } from '@/hooks/use-preview-store-theme';
import { useUserPreferences } from '@/hooks/use-user-preferences';
import { buildSkuWithQuantity } from '@/lib/product';
import useCartState from '@/stores/cart-state';
import { CartItem } from '@/types/cart';
import { useStoreSlug } from '@/hooks/use-store-slug';
import { Currency } from '@/gql';

const buildMonolithCheckoutUrl = (
  cart: CartItem[],
  storeSlug: string,
  storeUrl: string,
  currency: Currency,
  locale: string = 'en',
  fulfillmentRegion: FulfillmentRegion = DEFAULT_FULFILLMENT_REGION
) => {
  const skus = cart
    .map((item) => `${buildSkuWithQuantity(item.itemGroupId, item.variationId, item.sizeId, item.quantity)}`)
    .join(',');
  const url = new URL(MONOLITH_URL);
  url.pathname = '/buy/express_checkout';
  url.searchParams.set('currency', currency);
  url.searchParams.set('locale', locale);
  url.searchParams.set('payment_method', 'stripe');
  url.searchParams.set('ship_method', '1');
  url.searchParams.set('skus', skus);
  url.searchParams.set('store_id', storeSlug);
  url.searchParams.set('store_url', storeUrl);
  url.searchParams.set('fulfillment_region', fulfillmentRegion);
  return url.toString();
};

export const useGoToCheckout = () => {
  const { cart } = useCartState();
  const router = useRouter();
  const storeSlug = useStoreSlug();
  const { checkoutLegacyOnly } = useFlags();
  const locale = getUserLocale();
  const { createLocalCheckout } = useCheckoutData();
  const storeUrl = typeof window !== 'undefined' ? window.location.origin : `${storeSlug}.creator-spring.com`;
  const [creatingCheckout, setCreatingCheckout] = useState<boolean>(false);
  const previewStoreTheme = usePreviewStoreTheme();
  const previewMode = Boolean(previewStoreTheme?.preview);
  const { currency, fulfillment } = useUserPreferences();

  const canCheckout = useMemo(() => cart.length > 0 && !previewMode, [cart.length, previewMode]);
  const redirectToCheckout = useCallback(
    async (legacy?: boolean) => {
      if (!canCheckout) {
        return;
      }
      setCreatingCheckout(true);
      const checkoutID = await createLocalCheckout(storeSlug);
      const { region = DEFAULT_FULFILLMENT_REGION } = getFulfillmentDetail(fulfillment);
      let checkoutUrl: string | undefined;
      if (checkoutLegacyOnly || currency !== 'USD' || fulfillment !== 'USA' || legacy) {
        checkoutUrl = buildMonolithCheckoutUrl(cart, storeSlug, storeUrl, currency, locale, region);
      } else {
        checkoutUrl = `/checkout/${checkoutID}`;
      }
      router.push(checkoutUrl);
      setCreatingCheckout(false);
    },
    [
      canCheckout,
      createLocalCheckout,
      storeSlug,
      fulfillment,
      checkoutLegacyOnly,
      currency,
      router,
      cart,
      storeUrl,
      locale,
    ]
  );

  return { redirectToCheckout, creatingCheckout, canCheckout };
};
