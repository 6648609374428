import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import {
  CheckoutDeliveryOption,
  CheckoutErrorFragment,
  CheckoutFragment,
  CheckoutUnprocessableErrorsFragment,
} from '@/gql';
import { isCheckoutFragment } from '@/lib/gql';
import { checkoutErrorToDisplay } from '@/lib/checkout';

interface CheckoutState {
  checkoutId: string | undefined;
  promoCode: string | undefined;
  affiliate: string | undefined;
  trafficSource: string | undefined;
  deliveryOption: CheckoutDeliveryOption | undefined;
  platform: string | undefined;
  checkout: CheckoutFragment | undefined;

  checkoutError: string | undefined;
  promoCodeAtError: string | undefined;
  promoCodeError: string | undefined;
}

interface CheckoutActions {
  setPromoCode: (promoCode: string) => void;
  setDeliveryOption: (deliveryOption: CheckoutDeliveryOption | undefined) => void;
  setPlatform: (platform: string) => void;
  handleCheckoutOpResult: (
    checkout: CheckoutFragment | CheckoutErrorFragment | CheckoutUnprocessableErrorsFragment | undefined
  ) => void;
  clearCheckout(): void;
}

const useCheckoutState = create<CheckoutState & CheckoutActions>()(
  persist(
    (set, get) => ({
      checkoutId: undefined,
      promoCode: '',
      affiliate: '',
      trafficSource: '',
      checkout: undefined, // TODO: should we exclude checkout from persistance?
      platform: '',
      deliveryOption: 'fullStandardCost',
      checkoutError: undefined,
      promoCodeAtError: undefined,
      promoCodeError: undefined,
      setPlatform: (platform) => {
        set({ platform });
      },
      setDeliveryOption: (deliveryOption) => {
        set({ deliveryOption });
      },
      setPromoCode: (promoCode) => {
        set({ promoCode, promoCodeError: undefined, promoCodeAtError: undefined });
      },
      handleCheckoutOpResult: (
        result: CheckoutFragment | CheckoutErrorFragment | CheckoutUnprocessableErrorsFragment | undefined
      ) => {
        let checkout: CheckoutState['checkout'];
        let checkoutError: CheckoutState['checkoutError'];
        let promoCodeError: CheckoutState['promoCodeError'];

        const current = get();
        let promoCodeAtError: string | undefined;
        if (isCheckoutFragment(result) || !result) {
          checkout = result || undefined;
        } else {
          // TODO: race condition, promoCode could be being set to a value other than
          // the one that failed validation.  As part of checkout management synchronization
          // we should stash the promoCode before updating and pass it here. We could also save
          // the whole update request object and scrape from it
          promoCodeAtError = current.promoCode;
          ({ checkoutError, promoCodeError } = checkoutErrorToDisplay(result, { promoCode: promoCodeAtError }));
        }

        // push changes (if something changed, to keep down extra rendering)
        if (checkout) {
          const checkoutId = checkout?.id || undefined;
          if (current.checkout !== checkout || current.checkoutId !== checkoutId) {
            set({ checkout, checkoutId });
            if (current.promoCode) {
              window.localStorage.setItem('promoCode', current.promoCode);
            }
          }
        }
        if (current.checkoutError !== checkoutError || current.promoCodeError !== promoCodeError) {
          if (promoCodeError) {
            // note: see race condition for promoCodeAtError above
            // clear promoCode if there is an error
            set({ checkoutError, promoCodeError, promoCodeAtError, promoCode: undefined });
            window.localStorage.removeItem('promoCode');
          } else {
            // clear promoCodeAtError if this is no current error
            set({ checkoutError, promoCodeError, promoCodeAtError: undefined });
          }
        }
      },
      clearCheckout: () => {
        set({ checkout: undefined, checkoutId: undefined, checkoutError: undefined, promoCodeError: undefined });
      },
    }),
    { name: 'checkout-state', skipHydration: true }
  )
);

export default useCheckoutState;
