import { PaymentMethod, PaymentIntent, StripeError } from '@stripe/stripe-js';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface PaymentMethodState {
  paymentMethod: PaymentMethod | Record<string, never>;
  paymentIntent: PaymentIntent | Record<string, never>;
  paymentMethodError: StripeError | Record<string, never>;
  // stripe
  paymentIntentId: string | undefined;
  clientSecret: string | undefined;
  // spring
  paymentId: string | undefined;
  // paypal
  paymentToken: string | undefined;
  paymentMethodType: string | undefined;
}

interface PaymentMethodActions {
  setPaymentId: (id: string | undefined) => void;
  setPaymentToken: (id: string | undefined) => void;
  setPaymentMethod: (paymentMethod: PaymentMethod | Record<string, never>) => void;
  setPaymentIntentId: (paymentIntent: string | undefined) => void;
  setClientSecret: (clientSecret: string | undefined) => void;
  setPaymentMethodError: (error: StripeError | Record<string, never>) => void;
  setPaymentMethodType: (paymentMethodType: string | undefined) => void;
  reset: () => void;
}

// TODO: setting clientSecret to undefined causes stripe errors, revisit when cleanup is done
// would be nice to not be passing default data to stripe before we have an expectation it
// is valid
const defaultState: PaymentMethodState = {
  paymentMethodType: 'card',
  paymentMethod: {},
  paymentIntent: {},
  paymentMethodError: {},
  // stripe
  paymentIntentId: undefined,
  clientSecret: '',
  // spring
  paymentId: '',
  // paypal
  paymentToken: '',
};

const usePaymentMethodState = create<PaymentMethodState & PaymentMethodActions>()(
  persist(
    (set) => ({
      ...defaultState,
      setPaymentMethodType: (paymentMethodType) => {
        set({ paymentMethodType });
      },
      setPaymentMethodError: (error) => {
        set({ paymentMethodError: error });
      },
      setClientSecret: (clientSecret) => {
        set({ clientSecret });
      },
      setPaymentId: (id) => {
        set({ paymentId: id });
      },
      setPaymentToken: (token) => {
        set({ paymentToken: token });
      },
      setPaymentMethod: (paymentMethod = {}) => {
        set({ paymentMethod });
      },
      setPaymentIntentId: (paymentIntentId) => {
        set({ paymentIntentId });
      },
      reset: () => {
        set({
          paymentMethodType: 'card',
          paymentMethod: {},
          paymentIntent: {},
          // stripe
          paymentIntentId: undefined,
          clientSecret: '',
          // spring
          paymentId: '',
          // paypal
          paymentToken: '',
        });
      },
    }),
    { name: 'payment-state', skipHydration: true }
  )
);

export default usePaymentMethodState;
